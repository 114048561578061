import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute, ProtectedRouteProps, RouteUrl } from './AppRoutes';
import { AuthWrapper } from './components/ContextProviders/AuthWrapper';
import { Booking } from './components/Booking';
import { Layout } from './components/Layout';
import { Login } from './components/Login';
import { RouteHandler } from './components/ContextProviders/Routehandler';
import './custom.css';
import { UserContextProvider } from './Utils/UserContext';
import { isAuthenticated } from './Utils/AuthHandler';

export interface AppProps {

}
export interface AppState {
}
export default class App extends Component<AppProps, AppState> {


  render() {
    return (
      <AuthWrapper>
        <Layout>
          <RouteHandler>
           
          </RouteHandler>
        </Layout>
      </AuthWrapper>
    );
  }

}

