import { DefaultButton, Modal } from '@fluentui/react';
import * as React from 'react';
import { IPaymentModel } from '../Models/IPaymentModel';
import ITextItem from '../Models/ITextItem';
import { DataHandler } from '../Utils/DataHandler';

export interface IResumePurchaseProps {
  payment: IPaymentModel;
  setShowSpinner: Function
  setDeletePayment: Function
  texts: ITextItem[];
}

export const ResumePurchase: React.FunctionComponent<IResumePurchaseProps> = (props: React.PropsWithChildren<IResumePurchaseProps>) => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false)
  const _dataHandler: DataHandler = new DataHandler();
  return (
    <>
      <div className='contentBox'>
        <div>Du har et igangværende køb på {props.payment.amount + " " + props.payment.currency}</div>
        {props.payment.type == "Saldo" &&
          <div>Det nye køb vil tilføge beløbet til din <b>saldo</b></div>
        }
        {props.payment.type == "Månedskort" &&
          <div>Det nye køb vil tilkøbe et <b>månedskort</b></div>
        }
        <div className='contentBtnArea'>
          <DefaultButton className='button-primary-error' iconProps={{ iconName: "Delete" }} onClick={() => {
            props.setShowSpinner(true); _dataHandler.deleteUserPayment().then(e => {
              props.setShowSpinner(false);
              props.setDeletePayment()
            })
          }}>Slet køb</DefaultButton>
          <DefaultButton className='button-primary-color' iconProps={{ iconName: "PaymentCard" }} onClick={() => { _dataHandler.resumePayment().then(e => window.location.href = e) }}>Genoptag køb</DefaultButton>
        </div>
        <div className='contentBtnArea'>
          <DefaultButton className='' onClick={() => { setModalIsOpen(true) }} iconProps={{ iconName: "Info" }} text={"Se handelsbetingelser"}></DefaultButton>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onDismiss={() => setModalIsOpen(false)}
      >
        <div className='termsCondition'>
          {props.texts.find(e => e.key == "Betalingsbetingelser").value}
        </div>
      </Modal>
    </>
  );
};