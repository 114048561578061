export class UserModel {
    name: string;
    eMail:string;
    corp_Id:string;
    corp_name:string;
    paymentMethod:PaymentType
    saldo?:number;
    id:number;
    brugertype: string;
    cardExpireydate: Date;
}
export enum PaymentType{
    Saldo = 'Saldo',
    MkEgenbetaling = 'Månedskort: Egenbetaling',
    MkFakturering = 'Månedskort: Fakturering'
}