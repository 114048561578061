import { IUserContext } from "./IUserContext";

const tokenId: string = "_token";

export class AuthHandler{
    private username: string;
    private password: string;
    private _authContext: IUserContext;
    constructor(username: string, password:string, authContext: IUserContext){
        this.username = username;
        this.password = password;
        this._authContext = authContext;
    }
    private createAuthHeader():string{
        let headerType = "basic ";
        let token = btoa(`${this.username}:${this.password}`)

        return headerType + token;
    }

    async validateLogin():Promise<number>{
        const response = await fetch('Login', {
            method: 'GET',
            headers: {'Authorization': this.createAuthHeader(), 'Content-Type': 'application/json'
        }
        });
      if(response.status == 200){
          localStorage.setItem(tokenId, this.createAuthHeader())
          this._authContext.setAuth(true)
        } else {
            localStorage.removeItem(tokenId)
            this._authContext.setAuth(false)
      }
      return response.status;
    }

}
export const isAuthenticated = ():boolean => {
    let res = getHeaderAuth() != undefined;
    return res;
}
export const getHeaderAuth = () => {
    let test = localStorage.getItem(tokenId)
    return test
}
export const logOut = ()=>{
    localStorage.removeItem(tokenId)
    
}
