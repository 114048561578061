import { NavigateFunction, useNavigate } from "react-router-dom";
import { IDayOrder } from "../Models/IDayOrder";
import { IPaymentModel } from "../Models/IPaymentModel";
import ITextItem from "../Models/ITextItem";
import { MonthCardInfo } from "../Models/MonthCardInfo";
import { OrderLineModel } from "../Models/OrderLineModel";
import { UserModel } from "../Models/User";
import { getHeaderAuth } from "./AuthHandler";

export interface IDataHandler {
  getDayOrders(): Promise<IDayOrder[]>
}

export class DataHandler implements IDataHandler {
  constructor() {
  }

  async getDayOrders(): Promise<IDayOrder[]> {

    const response = await fetch('Order', {
      method: 'GET',
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      return undefined;
    }
    //   return data;
  }

  async updateOrder(order: IDayOrder, user: UserModel): Promise<IDayOrder[]> {
    let orderLine: OrderLineModel = {
      Dato: order.date,
      Guests: order.selectedGuests,
      SelectedInterval: order.selectedTimeSlot != undefined ? order.selectedTimeSlot : "",
      Vegans: order.selectedVegans,
      TotalSpots: undefined,
      Users_Id: user.id,
      Users_name: user.name,
      PrepForLupinvej: order.prepForLupinvej
    }
    const response = await fetch('Order', {
      method: 'Post',
      body: JSON.stringify(orderLine),
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      return undefined;
    }
    //   return data;
  }

  async getUser(): Promise<UserModel> {
    const response = await fetch('User', {
      method: 'GET',
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      return undefined;
    }
    //   return data;
  }

  async getTexts():Promise<ITextItem[]>{
    const response = await fetch('Info/Texts', {
      method: 'GET',
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      return undefined;
    }
  }
  async getMonthCardPrice():Promise<MonthCardInfo>{
    const response = await fetch('Info/MonthCardPrice', {
      method: 'GET',
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
    if (response.status == 200) {
      const data = await response.json();
      return {...data, expDate: new Date(data.expDate)};
    } else {
      return undefined;
    }
  }

  //---------------------payment--------------------------------


  async getUserPayment(): Promise<IPaymentModel> {
    const response = await fetch('Payment', {
      method: 'GET',
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      return undefined;
    }
  }

  async deleteUserPayment() {
    const response = await fetch('Payment', {
      method: 'Delete',
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
  }

  async startPayment(amount: number) {
    const response = await fetch('Payment', {
      method: 'Post',
      body: JSON.stringify(amount),
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      return undefined;
    }
  }
  async resumePayment() {
    const response = await fetch('Payment/Resume', {
      method: 'Post',
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      return undefined;
    }
  }
  async startCardPayment(amount: number) {
    const response = await fetch('Payment/CreateCard', {
      method: 'Post',
      body: JSON.stringify(amount),
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      return undefined;
    }
  }
  async resumeCardPayment() {
    const response = await fetch('Payment/ResumeCard', {
      method: 'Post',
      headers: {
        'Authorization': getHeaderAuth(), 'Content-Type': 'application/json'
      }
    });
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      return undefined;
    }
  }


}
