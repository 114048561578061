import * as React from 'react';
import { ProtectedRouteProps } from '../../AppRoutes';
import { PaymentType, UserModel } from '../../Models/User';
import { isAuthenticated } from '../../Utils/AuthHandler';
import { DataHandler } from '../../Utils/DataHandler';
import { IUserContext } from '../../Utils/IUserContext';
import UserContext, { UserContextProvider } from '../../Utils/UserContext';

export interface IAuthWrapperProps { }

export const AuthWrapper: React.FunctionComponent<IAuthWrapperProps> = (props: React.PropsWithChildren<IAuthWrapperProps>) => {
    const [isLoggedIn, setIsLoggedIn] = React.useState(isAuthenticated())
    const [user, setUser] = React.useState<UserModel>(undefined)
    const context: IUserContext = React.useContext(UserContext);
    const addToSaldo = (value: number) => {
        if (user.paymentMethod == PaymentType.Saldo) {
            setUser({ ...user, saldo: user.saldo + value });
        }
    }
    React.useEffect(() => {
        if (isLoggedIn && user == undefined) {
            new DataHandler().getUser().then((user: UserModel) => {
                setUser(user)
            })
        }
        if(!isLoggedIn && user != undefined){
            setUser(undefined)
        }
    })
    return (
        <>
            <UserContextProvider value={{ isAuthenticated: isLoggedIn, setAuth: (e: boolean) => { setIsLoggedIn(e) }, User: user, AddToUserSaldo: addToSaldo }}  >
                {props.children}

            </UserContextProvider >
        </>
    );
};