import { DefaultButton } from '@fluentui/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteUrl } from '../../AppRoutes';

export interface IErrorPageProps { }

export const ErrorPage: React.FunctionComponent<IErrorPageProps> = (props: React.PropsWithChildren<IErrorPageProps>) => {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search)
    const fromPage = queryParameters.get("from")

    return (
        <>
            {/* <div className='bookingHeaderContainer' >

        <div className='bookingName'> Velkommen {_userContext.User.name}</div>
        <div className='bookingStub'>Saldo: {_userContext.User.saldo}</div>
      </div> */}

            <div className='LoginContainer'>
                <div className='LoginLimiter'>
                    <div className='contentBox error'>
                        Købet fejlede
                    </div>
                    <div style={{ marginTop: "1em" }} className='contentBtnArea'>

                        <DefaultButton onClick={() => {
                            switch (fromPage) {
                                case "cart":
                                    return navigate(RouteUrl.cartPage)
                                case "cartcard":
                                    return navigate(RouteUrl.cartCardPage)
                                default:
                                    return navigate(RouteUrl.booking)
                            }
                        }}>Tilbage</DefaultButton>

                        <DefaultButton onClick={() => { navigate(RouteUrl.booking) }}>Tilbage til booking</DefaultButton>
                    </div>
                </div>
            </div>
        </>
    );
};