import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute, ProtectedRouteProps, RouteUrl } from '../../AppRoutes';
import UserContext from '../../Utils/UserContext';
import { Booking } from '../Booking';
import { Login } from '../Login';
import { CartCardPage } from '../PaymentPages/CartCardPage';
import { CartPage } from '../PaymentPages/CartPage';
import { ErrorPage } from '../PaymentPages/ErrorPage';
import { SuccessPage } from '../PaymentPages/SuccessPage';

export interface IRouteHandlerProps { }

export const RouteHandler: React.FunctionComponent<IRouteHandlerProps> = (props: React.PropsWithChildren<IRouteHandlerProps>) => {
    const authContext = React.useContext(UserContext);
    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        isAuthenticated: !!authContext.isAuthenticated,
        authenticationPath: RouteUrl.login,
      };
    return (
        <>
            <Routes>
                <Route path={RouteUrl.login} element={<Login />} />
                <Route path={RouteUrl.booking} element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Booking />} />} />
                <Route path={RouteUrl.errorPage} element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ErrorPage />} />} />
                <Route path={RouteUrl.successPage} element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SuccessPage />} />} />
                <Route path={RouteUrl.cartPage} element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<CartPage />} />} />
                <Route path={RouteUrl.cartCardPage} element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<CartCardPage />} />} />

            </Routes>
        </>
    );
};