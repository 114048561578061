import { DefaultButton, TextField } from '@fluentui/react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { RouteUrl } from '../AppRoutes';
import UserContext from '../Utils/UserContext';
import { AuthHandler } from '../Utils/AuthHandler';
import './Login.css';
export interface ILoginProps { }

export const Login: React.FunctionComponent<ILoginProps> = (props: React.PropsWithChildren<ILoginProps>) => {
  const [userName, setUserName] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<string>(undefined);
  const navigate = useNavigate();
  let authContext = React.useContext(UserContext)
  React.useEffect(()=>{
    if(authContext.isAuthenticated ){
      navigate(RouteUrl.booking)
    }
  }, [])
  const handleClick = () => {
    new AuthHandler(userName, password, authContext).validateLogin().then(statusCode => {
      if (statusCode == 200) {
        navigate(RouteUrl.booking);
      } else {
        setError("Forkert login")
      }});
  };
    const handleKeyPress = (e: any) => {
    if (e.charCode === 13) {
      handleClick()
    }
  }
  return (
    <>
      <div className='LoginContainer'>
        <div className='LoginLimiter'>

          <TextField
            className='LoginItem'
            value={userName}
            label="Email"
            onChange={(_, newValue) => setUserName(newValue != undefined ? newValue : "")} 
            onKeyPress={(e)=>handleKeyPress(e)}
            />
          <TextField
            className='LoginItem'
            value={password}
            onChange={(_, newValue) => setPassword(newValue != undefined ? newValue : "")}
            label="Adgangskode"
            type="password"
            errorMessage={error}
            canRevealPassword
            revealPasswordAriaLabel="Vis adgangskode" 
            onKeyPress={(e)=>handleKeyPress(e)}
            />
          <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
            <DefaultButton
            iconProps={{ iconName: 'SignIn' }}
             onClick={() => { handleClick() }} >Log på</DefaultButton>
          </div>
        </div>
      </div>
    </>
  );
};