import { DefaultButton, IconButton, Modal, TextField } from '@fluentui/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteUrl } from '../../AppRoutes';
import { IPaymentModel } from '../../Models/IPaymentModel';
import ITextItem from '../../Models/ITextItem';
import { DataHandler } from '../../Utils/DataHandler';
import UserContext from '../../Utils/UserContext';
import { LoadingPage } from '../LoadingPage';
import { ResumePurchase } from '../ResumePurchase';
import './Layout.css';

export interface ICartPageProps { }

export const CartPage: React.FunctionComponent<ICartPageProps> = (props: React.PropsWithChildren<ICartPageProps>) => {
    const [amount, setAmount] = React.useState<number>(100)
    const _userContext = React.useContext(UserContext);
    const [showSpinner, setShowSpinner] = React.useState(true)
    const [payment, setPayment] = React.useState<IPaymentModel>()
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false)
    const [texts, setTexts] = React.useState<ITextItem[]>([])
    const [isCreatingPayment, setICreatingPayment] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const _dataHandler: DataHandler = new DataHandler();
    React.useEffect(() => {
        Promise.all([
            _dataHandler.getUserPayment().then(e => { setPayment(e) }),
            _dataHandler.getTexts().then((e: ITextItem[]) => { setTexts(e) })
        ]).then(() => {
            setShowSpinner(false);

        })
    }, [])

    if (showSpinner) {
        return (<LoadingPage></LoadingPage>);
    }


    return (
        <>
            <div className='bookingHeaderContainer' >

                <div className='bookingName fs-4 fs-md-1'> Velkommen {_userContext.User.name}</div>
                <div className='bookingStub'>Saldo: {_userContext.User.saldo} kr.</div>
            </div>
            <div className='layoutContainer'>
                <div className='d-flex justify-content-center'>
                    <div className='contentLimiter'>

                        <h4>Tilføj til din saldo</h4>
                        {payment != undefined &&
                            <ResumePurchase
                                payment={payment}
                                setDeletePayment={() => setPayment(undefined)}
                                setShowSpinner={(e: boolean) => setShowSpinner(e)}
                                texts={texts}
                            />
                        }
                        {payment == undefined &&
                            <div className='contentBox'>
                                <div>
                                    <TextField min={100} label='Indtast et beløb i DKK (Mindst 100 kr.)' value={amount + ""} onChange={(_, e) => { setAmount(Number.parseFloat(e)) }}></TextField>
                                </div>
                                <div className='contentBtnArea'>
                                    <DefaultButton className='button-primary-color' disabled={isCreatingPayment} onClick={() => {
                                            setICreatingPayment(true);
                                            new DataHandler().startPayment(amount).then((e) => {
                                                setICreatingPayment(false);
                                                if (e != undefined) {
                                                    window.location.href = e;
                                                } else {
                                                    navigate(`${RouteUrl.errorPage}?from=cart`);
                                                }
                                            })
                                    }} iconProps={{ iconName: "PaymentCard" }} text="Start køb" ></DefaultButton>
                                    <DefaultButton className='' onClick={() => { setModalIsOpen(true) }} iconProps={{ iconName: "Info" }} text={"Se handelsbetingelser"}></DefaultButton>
                                </div>
                            </div>
                        }

                        <Modal
                            isOpen={modalIsOpen}
                            onDismiss={() => setModalIsOpen(false)}

                        >
                            <div className='termsCondition'>
                                {texts.find(e => e.key == "Betalingsbetingelser").value}
                            </div>
                        </Modal>
                        <div className='contentBtnArea'>
                            <DefaultButton className='bk' onClick={() => { navigate(RouteUrl.booking) }} text={"Tilbage til booking"}></DefaultButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};