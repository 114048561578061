import { DefaultButton } from '@fluentui/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteUrl } from '../../AppRoutes';

export interface ISuccessPageProps { }

export const SuccessPage: React.FunctionComponent<ISuccessPageProps> = (props: React.PropsWithChildren<ISuccessPageProps>) => {
  const navigate = useNavigate();
  return (
    <>
      <div className='LoginContainer'>
        <div className='LoginLimiter'>
          <div className='contentBox success'>
            Købet gennemført
          </div>
          <div style={{ marginTop: "1em" }} className='contentBtnArea'>

            <DefaultButton onClick={() => { navigate(RouteUrl.booking) }}>Tilbage til booking</DefaultButton>
          </div>
        </div>
      </div>

    </>
  );
};