import * as React from "react"
import { IDayOrder } from "../Models/IDayOrder"

const BookingContext = React.createContext<IBookingHandler>(undefined)
export const BookingContextProvider = BookingContext.Provider
export default BookingContext

export interface IBookingHandler{
    data:IDayOrder[];
    setData: Function
    // updateBooking(order:IDayOrder):IDayOrder;
    // getDayOrders():IDayOrder[];
}