import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { BookingContextProvider } from '../Utils/BookingContext';
import { BookingWrapper } from './ContextProviders/BookingWrapper';
import { NavMenu } from './NavMenu';

export class Layout extends Component<any> {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu />
        <BookingWrapper>
          {this.props.children}
        </BookingWrapper>
      </div>
    );
  }
}
// const _data = [
//   { Id: "1", DishName: "Svensk pølseret", Date: new Date(), TimeSlots: ["11:45-12.00", "12:00-12.15", "12:15-12.30", "12:30-12.45"], selectedVegans: 1 },
//   { Id: "2", DishName: "Minestrone suppe", Date: new Date(), TimeSlots: ["11:45-12.00", "12:00-12.15", "12:15-12.30", "12:30-12.45"] },
//   { Id: "3", DishName: "Fredags fristelser", Date: new Date(), TimeSlots: ["11:45-12.00", "12:00-12.15", "12:15-12.30", "12:30-12.45"] },
// ]

