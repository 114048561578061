import React, { Component, Context } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../Utils/AuthHandler';
import { RouteUrl } from '../AppRoutes';
import UserContext from '../Utils/UserContext';
import { IUserContext } from '../Utils/IUserContext';
import BookingContext, { IBookingHandler } from '../Utils/BookingContext';



export interface INavMenuProps { }

export const NavMenu: React.FunctionComponent<INavMenuProps> = (props: React.PropsWithChildren<INavMenuProps>) => {
  const navgate = useNavigate();
  const authContext = React.useContext(UserContext);
  const bookingContext = React.useContext(BookingContext);
  return (
    <header>
      <div className='headContainer'>

        <div style={{ flex: 1, alignItems: "center" }}>
          <img className='HeadLogo' src={require('./../Images/Madkontoret_logo.png')}></img>
        </div>
        {authContext.isAuthenticated &&
          <CommandBar
            items={_items((e: string) => {
              navgate(e);
            }, authContext, bookingContext)}

          />
        }
      </div>

    </header>
  );
};

const _items = (handleClick: Function, authContext: IUserContext, bookingContext: IBookingHandler): ICommandBarItemProps[] => [
  {
    key: 'login',
    text: 'Log af',
    iconProps: { iconName: 'SignOut' },
    onClick: () => {logOut(); authContext.setAuth(false);  handleClick(RouteUrl.login) },
  }
];


