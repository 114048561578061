import { Text } from '@fluentui/react';
import * as React from 'react';
import { Spinner } from 'reactstrap';

export interface ILoadingPageProps {}

export const LoadingPage: React.FunctionComponent<ILoadingPageProps> = (props: React.PropsWithChildren<ILoadingPageProps>) => {
  return (
    <div className='LoginContainer'>
        <div className='LoginLimiter'>
            <Spinner></Spinner>
            <Text>Indlæser siden</Text>
        </div>
    </div>
  );
};