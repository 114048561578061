import { addDays, PrimaryButton, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { IDayOrder } from '../Models/IDayOrder';
import BookingContext from '../Utils/BookingContext';
import { DayOrder } from './DayOrder';
import './Booking.css';
import { useNavigate } from 'react-router-dom';
import { RouteUrl } from '../AppRoutes';
import UserContext from '../Utils/UserContext';
import { PaymentType } from '../Models/User';
import { LoadingPage } from './LoadingPage';

export interface IBookingProps { }

export const Booking: React.FunctionComponent<IBookingProps> = (props: React.PropsWithChildren<IBookingProps>) => {
  const bookingProvider = React.useContext(BookingContext);
  const navigate = useNavigate();
  const authContext = React.useContext(UserContext);
  if (bookingProvider.data == undefined) {
    navigate(RouteUrl.login);
    return <></>
  }

  const ableToOrderCard = (): boolean => {
    var ableToOrderDate = new Date();
    ableToOrderDate = new Date(ableToOrderDate.setDate(ableToOrderDate.getDate()+5))
    if (new Date(authContext.User.cardExpireydate) < ableToOrderDate) {
      return true;
    }

    return false;
  }
  return (
    <>
      {authContext.User == undefined &&
        <LoadingPage></LoadingPage>
      }
      {authContext.User != undefined &&
        <>

          <div className='bookingHeaderContainer' >

            <div className='bookingName fs-4 fs-md-1'> Velkommen til {authContext.User.name}</div>
            {authContext.User.paymentMethod == PaymentType.Saldo &&
              <div className="bookingSaldoWrapper align-items-flex-end px-3 py-2">
                <div className='d-flex'>
                  <div className='pe-3 d-flex flex-grow-1 align-items-center'><b>Saldo: {authContext.User.saldo} kr.</b></div>
                  <div className=''><PrimaryButton style={{ width: 180 }} onClick={() => { navigate(RouteUrl.cartPage) }} className='button-primary-color' iconProps={{ iconName: "PaymentCard" }} text={"Tank op"}></PrimaryButton></div>
                </div>
                {authContext.User.brugertype == "Alm besøgende" &&
                  <div className='mt-2 d-flex justify-content-end'><PrimaryButton style={{ width: 180 }} onClick={() => { navigate(RouteUrl.cartCardPage) }} className='' iconProps={{ iconName: "PaymentCard" }} text={"Køb månedskort"}></PrimaryButton></div>
                }
              </div>
            }
            {authContext.User.paymentMethod == PaymentType.MkEgenbetaling &&
              <div className="bookingSaldoWrapper align-items-flex-end px-3 py-2">
                <div className='d-flex'>
                  <div className='pe-3 d-flex flex-grow-1 align-items-center'><b>Månedskort udløber: {new Intl.DateTimeFormat('da-DK').format(new Date(authContext.User.cardExpireydate))}</b></div>
                  <div className=''><PrimaryButton disabled={!ableToOrderCard()} style={{ width: 180 }} onClick={() => { navigate(RouteUrl.cartCardPage) }} iconProps={{ iconName: "PaymentCard" }} text={"Forny månedskort"}></PrimaryButton></div>
                </div>
              </div>
            }
          </div>
          {(authContext.User.paymentMethod == PaymentType.Saldo && bookingProvider.data.length > 0) &&
            <div className='px-3 py-2'>
              <span><b>Prisen for en bestilling er {bookingProvider.data[0].price} kr.</b></span>
            </div>
          }

          {bookingProvider.data.map((e: IDayOrder, index: number) => <DayOrder key={index} {...e}></DayOrder>)}
        </>
      }

    </>
  );
};