import { DefaultButton, IconButton, Modal, TextField } from '@fluentui/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteUrl } from '../../AppRoutes';
import { IPaymentModel } from '../../Models/IPaymentModel';
import ITextItem from '../../Models/ITextItem';
import { MonthCardInfo } from '../../Models/MonthCardInfo';
import { DataHandler } from '../../Utils/DataHandler';
import UserContext from '../../Utils/UserContext';
import { LoadingPage } from '../LoadingPage';
import { ResumePurchase } from '../ResumePurchase';
import './Layout.css';

export interface ICartCardPageProps { }

export const CartCardPage: React.FunctionComponent<ICartCardPageProps> = (props: React.PropsWithChildren<ICartCardPageProps>) => {
    const [cardInfo, setCardInfo] = React.useState<MonthCardInfo>(null)
    const _userContext = React.useContext(UserContext);
    const [showSpinner, setShowSpinner] = React.useState(true)
    const [payment, setPayment] = React.useState<IPaymentModel>()
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false)
    const [texts, setTexts] = React.useState<ITextItem[]>([])
    const [isCreatingPayment, setICreatingPayment] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const _dataHandler: DataHandler = new DataHandler();
    React.useEffect(() => {
        Promise.all([
            _dataHandler.getUserPayment().then(e => { setPayment(e) }),
            _dataHandler.getMonthCardPrice().then(e => { setCardInfo(e) }),
            _dataHandler.getTexts().then((e: ITextItem[]) => { setTexts(e) })
        ]).then(() => {
            setShowSpinner(false);
        })
    }, [])

    if (showSpinner) {
        return (<LoadingPage></LoadingPage>);
    }
    const getCardPrice = () => {
        let price = cardInfo.price - _userContext.User.saldo;
        if (price <= 0) {
            price = 0;
        }
        return price;
    }
    const ableToOrderCard = (): boolean => {
        var ableToOrderDate = new Date();
        ableToOrderDate = new Date(ableToOrderDate.setDate(ableToOrderDate.getDate() + 5))
        if (new Date(_userContext.User.cardExpireydate) < ableToOrderDate) {
            return true;
        }

        return false;
    }

    return (
        <>
            <div className='bookingHeaderContainer' >

                <div className='bookingName fs-4 fs-md-1'> Velkommen {_userContext.User.name}</div>
            </div>
            <div className='layoutContainer'>
                <div className='d-flex justify-content-center'>
                    <div className='contentLimiter'>

                        <h4>Køb månedskort</h4>
                        {payment != undefined &&
                            <ResumePurchase
                                payment={payment}
                                setDeletePayment={() => setPayment(undefined)}
                                setShowSpinner={(e: boolean) => setShowSpinner(e)}
                                texts={texts}
                            />
                        }
                        {payment == undefined &&
                            <div className='contentBox'>
                                <div>
                                    <div>Prisen for et månedskort er {cardInfo.price}kr.</div>
                                    {_userContext.User.saldo > 0 &&
                                        <>
                                            <div>Da du har penge på din saldo, modregner vi beløbet fra din saldo først.</div>
                                            <div>Din pris vil derfor være {cardInfo.price} - {_userContext.User.saldo} = {getCardPrice()}kr. </div>
                                        </>
                                    }
                                    <div>Efter købet vil dit månedskort være gyldigt til den <b>{new Intl.DateTimeFormat('da-DK').format(cardInfo.expDate)}</b></div>

                                    <div className='w-100 pt-2'>Beløb at betale: <b className='ps-2'>{getCardPrice()} kr.</b></div>
                                    {/* <TextField min={100} label='Indtast et beløb i DKK (Mindst 100 kr.)' value={amount + ""} onChange={(_, e) => { setAmount(Number.parseFloat(e)) }}></TextField> */}
                                </div>
                                <div className='contentBtnArea'>
                                    <DefaultButton disabled={!ableToOrderCard() || isCreatingPayment} className='button-primary-color' onClick={() => {
                                            setICreatingPayment(true);
                                            new DataHandler().startCardPayment(cardInfo.price).then((e) => {
                                                if (e != undefined) {
                                                    window.location.href = e;
                                                } else {
                                                    navigate(`${RouteUrl.errorPage}?from=cartcard`);
                                                }
                                            })
                                    }} iconProps={{ iconName: "PaymentCard" }} text="Start køb" ></DefaultButton>
                                    <DefaultButton className='' onClick={() => { setModalIsOpen(true) }} iconProps={{ iconName: "Info" }} text={"Se handelsbetingelser"}></DefaultButton>
                                </div>
                            </div>
                        }
                        <Modal
                            isOpen={modalIsOpen}
                            onDismiss={() => setModalIsOpen(false)}
                        >
                            <div className='termsCondition'>
                                {texts.find(e => e.key == "Betalingsbetingelser").value}
                            </div>
                        </Modal>

                        <div className='contentBtnArea'>
                            <DefaultButton className='bk' onClick={() => { navigate(RouteUrl.booking) }} text={"Tilbage til booking"}></DefaultButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};