import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteUrl } from '../../AppRoutes';
import { IDayOrder } from '../../Models/IDayOrder';
import { getHeaderAuth } from '../../Utils/AuthHandler';
import { BookingContextProvider } from '../../Utils/BookingContext';
import { DataHandler } from '../../Utils/DataHandler';
import UserContext from '../../Utils/UserContext';
import { LoadingPage } from '../LoadingPage';

export interface IBookingWrapperProps { }

export const BookingWrapper: React.FunctionComponent<IBookingWrapperProps> = (props: React.PropsWithChildren<IBookingWrapperProps>) => {
    const [data, setData] = React.useState<IDayOrder[]>(undefined)
    const [loading, setLoading] = React.useState<boolean>(true);
    const authContext = React.useContext(UserContext);

    const navigate = useNavigate();
    React.useEffect(() => {
        
        if (getHeaderAuth() == undefined && loading) {
            setLoading(false);
            navigate(RouteUrl.login);
        }
        if(!authContext.isAuthenticated && data != undefined){
            setData(undefined)
        }
        if (data == undefined) {

            new DataHandler().getDayOrders().then(e => {
                if (e != undefined) {
                    setData(e.sort((a, b) => a.date < b.date ? -1 : 1));
                } else {
                    setData(e);
                }
                setLoading(false);
                
            })
        }
    })
    if (loading) {
        return (<LoadingPage></LoadingPage>)
    }
    return (
        <>
            <BookingContextProvider value={{ data: data, setData }}>

                {props.children}
            </BookingContextProvider>
        </>
    );
};


