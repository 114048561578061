
import React from "react";
import { Navigate, Route, useNavigate } from "react-router-dom";
import { isAuthenticated } from './Utils/AuthHandler';
import { Login } from "./components/Login";
import UserContext from "./Utils/UserContext";

// const AppRoutes = [
//   {
//     index: true,
//     path: '/login',
//     element: <Login />
//   },
//   {
//     index: false,
//     element: <Booking />
//   }
// ];
export enum RouteUrl{
  login= "/",
  booking = "/Booking", 
  successPage = "/Success", 
  errorPage = "/Error", 
  cartPage = "/Cart", 
  cartCardPage = "/Card", 

}
export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

export function ProtectedRoute({ authenticationPath, outlet}: ProtectedRouteProps) {
  let context = React.useContext(UserContext)
  if(context.isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
};

// export default AppRoutes;
