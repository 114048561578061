import { Text, Dropdown, IDropdownStyles, DropdownMenuItemType, Stack, IDropdownOption, Toggle } from '@fluentui/react';
import * as React from 'react';
import { IDayOrder } from '../Models/IDayOrder';
import { ITimeslot } from '../Models/ITimeSlot';
import UserContext from '../Utils/UserContext';
import BookingContext from '../Utils/BookingContext';
import { DataHandler } from '../Utils/DataHandler';
import './DayOrderStyles.css';
import { PaymentType } from '../Models/User';


export const DayOrder: React.FunctionComponent<IDayOrder> = (props: React.PropsWithChildren<IDayOrder>) => {
    const bookingProvider = React.useContext(BookingContext);
    const userContext = React.useContext(UserContext);
    const dataHandler = new DataHandler();
    let slots: IDropdownOption[] = props.timeSlots.map(e => { return { data: e, key: e.interval, disabled: e.freeSpots <= 0, text: `${e.interval} ${(e.interval != "Til Lupinvej" ? `(${e.freeSpots} ledige) ` : "")} `, itemType: DropdownMenuItemType.Normal } })
    slots.unshift({ data: undefined, key: "none", text: "Nej tak", itemType: DropdownMenuItemType.Normal })

    const handleUpdate = async (order: IDayOrder, saldoCheck: boolean) => {

        if (userContext.User.paymentMethod == PaymentType.Saldo) {

            if (props.selectedTimeSlot == undefined && userContext.User.saldo < order.price && saldoCheck) {
                alert("Du har ikke nok penge på din saldo til at kunne bestille denne ordre");
                return
            }
        }
        if (order.selectedTimeSlot == undefined) {
            order.selectedVegans = 0;
            order.selectedGuests = 0;
            order.prepForLupinvej = false;
        }
        if (userContext.User.paymentMethod == PaymentType.Saldo) {
            let orderSum: number = 0;
            if (props.selectedTimeSlot == undefined) { orderSum += -order.price; }
            if (props.selectedTimeSlot != undefined && order.selectedTimeSlot == undefined) { orderSum += order.price; }

            userContext.AddToUserSaldo(orderSum)
        }
        let index = bookingProvider.data.findIndex(e => e.id == order.id);
        let data = bookingProvider.data
        data[index] = order;
        await dataHandler.updateOrder(order, userContext.User);
        bookingProvider.setData([...data]);
    }

    const findFreeSpotsForDropdown = (): number => {
        let val = props.timeSlots.find(e => e.interval == props.selectedTimeSlot)
        let valToReturn : number = 1;
        if (val != undefined) {
            valToReturn +=  val.freeSpots -1 ;
        }

        return valToReturn;
    }
    const createArrayFromNumber = (val: number): number[] => {
        let _array: number[] = []
        for (let i = 0; i < val; i++) {
            _array.push(i);
        }
        return _array;
    }
    const lockedForToday = () => {
        let newDate = new Date();
        let propsDate = new Date(props.date);
        let day: boolean = propsDate.getDate() == newDate.getDate();
        let month: boolean = propsDate.getMonth() == newDate.getMonth();
        let year: boolean = propsDate.getFullYear() == newDate.getFullYear();
        let past9: boolean = newDate.getHours() >= 9
        return day && month && year && past9;
    }
    const LockedForOrder = () => {

        if (userContext.User.paymentMethod == PaymentType.MkEgenbetaling && props.date > userContext.User.cardExpireydate) {
            return true;
        }
        return false;
    }

    return (
        <>
            <div className='DayOrderContainer'>
                <Stack>
                    <div className='OrderDate'> <b>{new Intl.DateTimeFormat('da-DK', { dateStyle: 'full' }).format(new Date(props.date))}</b></div>
                    <div className='OrderDish'>{props.dishName}</div>
                    {lockedForToday() && <div className='OrderLineLocked'>Tiden er låst for i dag, kantinens sidste bestillingstidspunkt er kl 09.00</div>}
                    {LockedForOrder() && <div className='OrderLineLocked'>Dit månedskort er ikke gyldigt denne dag, forny for at booke frokost</div>}
                    {props.selectedTimeSlot != undefined && <div className='OrderlineItemselected'>Frokost er bestilt</div>}

                    {props.timeSlots.length == 0 &&
                        <div className='OrderlineItem'>Mangler mulige tider, kontakt Madkontoret</div>
                    }

                    {props.timeSlots.length > 0 &&
                        <div style={{ marginLeft: "-.5em" }} className='d-flex flex-wrap'>

                            <Dropdown
                                className='OrderlineItem'
                                placeholder="Nej tak"
                                label='Bestil:'
                                selectedKey={props.selectedTimeSlot != undefined ? props.selectedTimeSlot : "none"}
                                options={slots}
                                onChange={(_, val: IDropdownOption<ITimeslot>) => { handleUpdate({ ...props, selectedTimeSlot: val.data != undefined ? val.data.interval : undefined }, true) }}
                                disabled={lockedForToday() || LockedForOrder()}

                            />


                            <Dropdown
                                className='OrderlineItem'
                                placeholder="0"
                                label='Antal gæster:'
                                defaultSelectedKey={props.selectedGuests != undefined ? "guest_" + props.selectedGuests : "guest_" + 0}
                                options={createArrayFromNumber(findFreeSpotsForDropdown()).map((e: number) => { return { data: e, key: "guest_" + e, text: e + "", itemType: DropdownMenuItemType.Normal } })}
                                onChange={(_, val) => { handleUpdate({ ...props, selectedGuests: val.data }, false) }}
                                disabled={props.selectedTimeSlot == undefined || lockedForToday() || LockedForOrder()}
                            />
                            <Dropdown
                                className='OrderlineItem'
                                placeholder="0"
                                label='Vegetarer i alt:'
                                selectedKey={props.selectedVegans != undefined ? "vegan_" + props.selectedVegans : "vegan_" + 0}
                                options={createArrayFromNumber(2 + (props.selectedGuests != undefined ? props.selectedGuests : 0)).map(e => { return { data: e, key: "vegan_" + e, text: e + "", itemType: DropdownMenuItemType.Normal } })}
                                onChange={(_, val) => { handleUpdate({ ...props, selectedVegans: val.data }, false) }}
                                disabled={props.selectedTimeSlot == undefined || lockedForToday() || LockedForOrder()}
                            />
                        </div>
                    }
                </Stack>
            </div>
        </>
    );

};